import React, { Component } from "react";
import sendRequest from "../../../utils/ajaxUtils";
import "../../../sass/components/browse-category.scss";
import StandardButton from "../../../components/form_components/StandardButton";
import { BROWSE_COURSES } from "../../../routes";

class BrowseCategory extends Component{
    state = {
        categories : this.props.categories
    }
    componentDidMount(){        
    }    

    browseCoursesByCategory(id){
        this.props.history.push(`${BROWSE_COURSES}?category=${id}`)
    }

    renderCategories(){
        return this.state.categories.map((category) => {
            return <div className="col-12 col-md-6 col-xl-4 pl-4 pr-4 mb-4 text-left">
                <div className="category" style={{
                backgroundColor : category['color']
            }}>                
                    <div>
                        <img src={category.logo_path} alt=""/>
                    </div>
                    <div className="text-size-3 mt-2">
                        {category.name}
                    </div>
                    <div className="text-size-4 mt-2 desc">
                        {category.description}
                    </div>
                    <div>
                        <StandardButton
                            className="cta-button text-white pl-0 text-bold text-left text-size-4"
                            color="btn-outline"
                            bold={true}
                            block={false}                        
                            text={<>Start now<img src='/courses/assets/img/start_arrow.png' alt="arrow" className="ml-3"/></>}
                            onClick={() => {this.browseCoursesByCategory(category.id)}}
                        />
                    </div>
                </div>
            </div>
        })
    }

    render(){
        return <div className="mt-5 browse-category mb-5 text-center">
            <h2 className="text-size-2 mb-4">Learn by <span className="text-orange">category</span></h2>            
            <div className="row">
                {this.renderCategories()}
            </div>
        </div>
            
    }
}

export default BrowseCategory;