import React, { Component } from "react";
import "../../sass/components/top-navigation.scss";
import "@fortawesome/fontawesome-free/css/all.css";
import StandardButton from "../form_components/StandardButton";
import { HEADER_LAYOUT } from "../../constants";
import { LMS, BROWSE_COURSES } from '../../routes';

 class TopNavigation extends Component {  
  commonMenuItems = [{
    title:<>
      <img className="edit-icon large" src="/courses/assets/icons/courses.svg" alt=""></img>
      <span className="ml-2">Courses</span>
    </>,
    classes : "border-0 btn-outline-dark",
    icon : "/courses/assets/icons/courses.svg",
    link : LMS
  },{
    title:<>
      <img className="edit-icon large" src="/courses/assets/icons/courses.svg" alt=""></img>
      <span className="ml-2">Browse Courses</span>
    </>,
    classes : "border-0 btn-outline-dark",
    icon : "/courses/assets/icons/courses.svg",
    link : BROWSE_COURSES
  }]  
  

  
  renderMenu(){
    let menuItems = this.commonMenuItems;    
    return menuItems.map((item,index) =>{
      let classes = 'ml-3 rounded d-none d-xl-inline-block';
      if(item.classes){
        classes += " " + item.classes
      }
      return (
        <StandardButton key={index} className={classes} size="btn-sm" text={item.title} onClick={() => this.openPage(item)} />
      )
    })
  }

  openPage(item){    
    if(item.function){      
      item.function();
    }
    if(item.link){      
      window.location = item.link;
    }
  }
  render() {
    return (
      <>
       <div className="top-navigation col-12">
        
        <div className="div-adjuster"></div>
        {/* To account for height of navbar,all elements can resume immediately after top navigation */}
        <div className="fixed-top">
          <div className="row">
            <div className={HEADER_LAYOUT}>
            
              <nav className="navbar d-block">   
              <a href="/">
                <img className="logo d-inline-block" src="/courses/assets/img/logo.svg" alt="logo"/>              
              </a>
                {this.renderMenu()}
              {this.props.inMenu?<i className="d-xl-none fas fa-times float-right mt-3" role="button" onClick={this.props.onMenuClick}></i>:
              <div className="d-inline-block float-right d-xl-none" onClick={this.props.onMenuClick} role="button">
                <i className="fas fa-bars mt-3"></i>
                <span className="ml-1">Menu</span>
              </div>
              }              
              </nav>
            </div>
          </div>
        </div>
      </div>
          {/* <Sidebar isOpen={this.state.isOpen}></Sidebar> */}
      </>
      
     
    );
  }
}



export default TopNavigation