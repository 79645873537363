import Axios from "axios";
import { BASE_URL } from "../constants";
import { evaluateStringTemplate } from 'string-template-parser';
import showNotification from "./notificationUtils";


export default function sendRequest(requestObject) {
    return new Promise((resolve, reject) => {
        var requestPromise;

        if(requestObject.handleError === undefined){
            requestObject.handleError = true;
        }
        if(!requestObject.outsideRequest){
            requestObject.url = (requestObject.BASE_URL || BASE_URL) + requestObject.url;
        }                                     
        
        if (requestObject.data !== undefined) {
            requestPromise = Axios[requestObject.type](requestObject.url, requestObject.data)
        } else {
            requestPromise = Axios[requestObject.type](requestObject.url)
        }
        
        if(requestObject.showLoading){
            //TODO put loader.
        }
    
        requestPromise
            .then(res => {
                if (res.status === 200 || res.status === 204) {
                    resolve(res.data);
                }else{
                    reject(res);
                }
            }).catch(err => {
                if(!requestObject.handleError){
                    return reject(err);
                }
                    if(err & err.response){
                        if (err.response.status === 404) {
                            showNotification("Resource Not found", {type : 'error'});
                        }else{
                            showNotification("Some error with the request", {type : 'error'});
                        }                                
                    }else{
                        showNotification("System Error", {type : "error"});
                    }
                    return reject(err);  
                
            })
    })

}