var numberFormatter = Intl.NumberFormat('currency')

export  function convertObjectToDropdown(data) {
    let dropdown = [];
    for (let key in data) {
        dropdown.push({
            value: parseInt(key),
            label: data[key]
        })
    }
    return dropdown;
};
export  function convertArrayToDropdown(data) {
    let dropdown = [];
    for (let obj of data) {
        dropdown.push({
            value: obj.id,
            label: obj.name
        })
    }
    return dropdown;
};
export  function checkKeys(data, keys) {
    for (let key of keys) {
        if (data[key] === undefined || data[key] === '' || data[key] === null) {
            return false;
        }
    }
    return true;
};
export  function getPopulatedListWithObject(data, values) {
    let arr = [];
    for (let key of data) {
        if (values[key]) {
            arr.push(values[key])
        }
    }
    return arr;
};
export  function isMobile() {
    return window.innerWidth < 476;
};
export  function updatedMobileFormat(value) {
    if (value.substring(0, 2) === '27') {
        return "0" + value.slice(2);
    }
    return value;
};
export  function convertCurrencyFormat(amount) {
    return numberFormatter.format(amount)
};

export function stringToHTML  (str) {
	var parser = new DOMParser();
	var doc = parser.parseFromString(str, 'text/html');
	return doc.body.textContent;
};