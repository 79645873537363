import courseSortEnum from '../enums/courseSortEnum';

export default [{
    value : courseSortEnum.RECENTLY_ADDED,
    label : "Recently Added"
},{
    value : courseSortEnum.MOST_POPULAR,
    label : "Most Popular"
},{
    value : courseSortEnum.CHRONOLOGICAL,
    label : "Chronological"
}]