import React, { Component } from "react";
import StandardButton from "../../../components/form_components/StandardButton";
import { BROWSE_COURSES } from "../../../routes";
import "../../../sass/components/why-giraffe.scss";

class WhyGiraffe extends Component{
    redirectToBrowseCourses = () => {
        this.props.history.push(BROWSE_COURSES);
    }
    render(){
        return <div className="text-center mt-5 why-giraffe">
            <h2 className="text-size-2">Why take Giraffe’s<span className="text-orange">  free online courses? </span></h2>            
            <div className="row">
                <div className="col-12 col-md-6 col-xl-4 pl-4 pr-4">
                    <div className="why-tile">
                        <img src="/courses/assets/img/successful.png" alt=""/>
                        <div className="text-size-4 text-bold mt-4">Find out which jobs you will be most successful at and gain the skills to become more employable.</div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4 pl-4 pr-4">
                    <div className="why-tile">
                        <img src="/courses/assets/img/manage.png" alt=""/>
                        <div className="text-size-4 text-bold mt-4">Learn how to manage your money so you get richer, not poorer.</div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4 pl-4 pr-4">
                    <div className="why-tile">
                        <img src="/courses/assets/img/free.png" alt=""/>
                        <div className="text-size-4 text-bold mt-4">All courses are free, short and work perfectly on your cellphone.</div>
                    </div>
                </div>
                
            </div>
            <div className="text-center mt-5">
                <StandardButton
                    className="cta-button"                
                    color="btn-primary"
                    bold={true}
                    block={false}                        
                    text="Browse all courses"
                    onClick={this.redirectToBrowseCourses}
                />      
            </div>
        </div>
    }
}

export default WhyGiraffe;