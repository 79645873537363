import React, { Component } from "react";
import Banner from "../../components/common_components/Banner";
import BreadCrumb from "../../components/common_components/BreadCrumb";
import LoadingAnimation from "../../components/common_components/LoadingAnimation";
import StandardButton from "../../components/form_components/StandardButton";
import { READING_LAYOUT } from "../../constants";
import "../../sass/pages/course-chapter-page.scss";
import sendRequest from "../../utils/ajaxUtils";
import { Modal } from "react-bootstrap";
import {
  BROWSE_COURSES,
  COURSE_DETAIL_PAGE_ROUTE,
  FEEDBACK_PAGE_ROUTE,
  LANDING_PAGE_ROUTE,
} from "../../routes";
import { Link } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import classNames from "classnames";
class CourseChapterPage extends Component {
  state = {
    fetchDataComplete: false,
    showChapterJump: false,
  };

  componentDidMount() {
    let courseUrl = "public/course/" + this.props.match.params.id; 
    sendRequest({
      type: "get",
      url: courseUrl,
    })
      .then((courseMetaData) => {
        courseMetaData.chapters.sort(function (a, b) {
          return a.chronology - b.chronology;
        });
      courseMetaData.current_chapter_chronology = 1;        
        this.setState({
          fetchDataComplete: true,
          courseDetail: courseMetaData,
          chapterDetail: courseMetaData.chapters[0],
          courseMetaData: courseMetaData,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          fetchDataComplete: true,
        });
      });
  }
  async gotoChapter(chapter) {
    let requestedChapter = chapter;
    if (requestedChapter > this.state.courseMetaData.chapters.length) {
      return this.props.history.push(
        FEEDBACK_PAGE_ROUTE.replace(
          ":id",
          this.props.match.params.id
        )
      );
    } else {
      this.setState({
        chapterDetail: this.state.courseDetail.chapters[chapter - 1],
        showChapterJump: false,
      });
    }
  }
  toggleChapterJump = () => {
    this.setState({
      showChapterJump: !this.state.showChapterJump,
    });
  };
  renderChapters() {
    return this.state.courseMetaData.chapters
      .sort((a, b) => {
        return a.chronology - b.chronology;
      })
      .map((chapter) => {
        let chapterClasses = classNames("chapter d-flex", {
          current:
            chapter.chronology ===
            this.state.courseDetail.current_chapter_chronology,
          completed:
            chapter.chronology ===
            this.state.courseDetail.current_chapter_chronology,
        });
        return (
          <div
            className="col-12 mt-3"
            onClick={() => this.gotoChapter(chapter.chronology)}
          >
            <div className={chapterClasses}>
              <div className="chapter-number align-self-center text-size-5">
                {chapter.chronology}
              </div>
              <div className="text-bold align-self-center text-size-5">
                {chapter.name}
              </div>
              <div className="ml-auto align-self-center">
                <i class="fa fa-check"></i>
              </div>
            </div>
          </div>
        );
      });
  }
  goToCourse = () => {
    this.props.history.push(
      COURSE_DETAIL_PAGE_ROUTE.replace(
        ":id",
        this.state.courseMetaData.id
      ).replace(
        ":name",
        this.state.courseMetaData.name.toLowerCase().replace(/ /g, "-")
      )
    );
  };
  render() {
    const { courseDetail, chapterDetail, showChapterJump } = this.state;

    if (!this.state.fetchDataComplete) {
      return <LoadingAnimation />;
    }
    courseDetail.complete_percentage = Math.floor(
      ((courseDetail.current_chapter_chronology - 1) /
        courseDetail.chapters.length) *
        100
    );
    return (
      <div className="course-chapter">
        <MetaTags>
          <title>
            Giraffe Free Course {courseDetail.name} Module{" "}
            {chapterDetail.name}
          </title>
          <meta
            name="description"
            content={`Giraffe Free Course ${courseDetail.name} Module ${chapterDetail.name} will help enhance your skills and make you more employable`}
          />
        </MetaTags>
        <div className="row">
          <div className="col-12">
            <BreadCrumb>
              <Link to={LANDING_PAGE_ROUTE}>Home</Link> /{" "}
              <Link to={BROWSE_COURSES}>Courses</Link> /{" "}
              <span className="text-bold">{courseDetail.name}</span>
            </BreadCrumb>
          </div>
          <div className="col-12">
            <Banner heading={courseDetail.name}>
              <div className="progress-cover">
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style={{ width: courseDetail.complete_percentage + "%" }}
                    aria-valuenow={courseDetail.complete_percentage}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="text-size-5">
                  {courseDetail.complete_percentage}% complete
                </div>
              </div>
            </Banner>
          </div>
          <div className="col-12 mt-3">
            <div className="row">
              <div className={READING_LAYOUT}>
                <div className="chapter-section mb-3">
                  <div className="row mt-3">
                    <div className="col-12 pl-5 pr-5 mb-3 text-center text-md-left">
                      <div className="marker-number">
                        {chapterDetail.chronology}
                      </div>
                      <div className="chapter-name">{chapterDetail.name}</div>
                    </div>
                    <div
                      className="col-12 chapter-content pl-5 pr-5 ck-content"
                      dangerouslySetInnerHTML={{
                        __html: chapterDetail.content,
                      }}
                    ></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 text-center pl-5 pr-5 d-block d-md-none">
                      <StandardButton
                        className="cta-button ml-md-3 mt-md-0 next-btn d-block mx-auto"
                        color="btn-primary"
                        bold={true}
                        block={false}
                        text="Next"
                        onClick={() =>
                          this.gotoChapter(chapterDetail.chronology + 1)
                        }
                      />
                      <StandardButton
                        className="cta-button mt-3 d-block mx-auto"
                        color="btn-outline-dark"
                        bold={true}
                        block={false}
                        text="Previous"
                        disabled={chapterDetail.chronology === 1}
                        onClick={() =>
                          this.gotoChapter(chapterDetail.chronology - 1)
                        }
                      />

                      <StandardButton
                        className="cta-button float-md-right mt-3 mt-md-0"
                        color="btn-secondary"
                        bold={true}
                        block={false}
                        text="Overview"
                        onClick={this.toggleChapterJump}
                      />
                    </div>

                    <div className="col-12 text-center text-md-left pl-5 pr-5 d-none d-md-block">
                      <StandardButton
                        className="cta-button"
                        color="btn-outline-dark"
                        bold={true}
                        block={false}
                        text="Previous"
                        disabled={chapterDetail.chronology === 1}
                        onClick={() =>
                          this.gotoChapter(chapterDetail.chronology - 1)
                        }
                      />
                      <StandardButton
                        className="cta-button ml-md-3 mt-3 mt-md-0"
                        color="btn-primary"
                        bold={true}
                        block={false}
                        text="Next"
                        onClick={() =>
                          this.gotoChapter(chapterDetail.chronology + 1)
                        }
                      />
                      <StandardButton
                        className="cta-button float-md-right mt-3 mt-md-0"
                        color="btn-secondary"
                        bold={true}
                        block={false}
                        text="Overview"
                        onClick={this.toggleChapterJump}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showChapterJump}>
          <Modal.Body>
            <div className="float-right">
              <i className="fas fa-times" onClick={this.toggleChapterJump}></i>
            </div>
            <div className="text-bold">Jump to a specific section</div>

            <div className="col-12 mt-3" onClick={this.goToCourse}>
              <div className="course-overview-link d-flex">
                <div className="chapter-number align-self-center text-size-5">
                  <img src="/courses/assets/img/home.png" alt="" />
                </div>
                <div className="text-bold align-self-center text-size-5">
                  Course Overview
                </div>
                <div className="ml-auto align-self-center">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
            </div>

            {this.renderChapters()}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default CourseChapterPage;
