import React, { Component } from "react";
import "./App.css";
import "./sass/main.scss";
import { BOOTSTRAP_APP_COLUMN_LAYOUT } from "./constants";
import MainRoutes from "./routes/MainRoutes";
import TopNavigation from "./components/common_components/TopNavigation";
import Footer from "./components/common_components/Footer";
import Sidebar from "./components/common_components/Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class App extends Component {
  state = {        
    isOpen: false,
  };
  toggleMenu = (val) => {
    this.setState({
      isOpen: val,
    });
  };
  
  
  render() {    
    return (
      <>
      <ToastContainer pauseOnFocusLoss={false} />
        <Sidebar
          isOpen={this.state.isOpen}
          history={this.props.history}
          closeMenu={this.toggleMenu}
          location={this.props.location}
        />
        <div className="container-fluid">
          <div className="row justify-content-center">
            <TopNavigation
              onMenuClick={() => this.toggleMenu(true)}
              history={this.props.history}
            />
            <div
              className={BOOTSTRAP_APP_COLUMN_LAYOUT + " giraffe-container-h"}
            >
              <MainRoutes />
            </div>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}

export default App;
