export const BASE_HOSTNAME = process.env.REACT_APP_BASE_HOST;
export const BASE_URL = `https://${process.env.REACT_APP_BASE_HOST}`;

export const LOGIN_ROUTE =  BASE_URL+ "/profile/login?redirect=lms";
export const SIGN_UP_LANDING_ROUTE = BASE_URL + "/profile/sign-up?channel=learn-signup&redirect=lms";


export const RETURNING_USER_ROUTE = BASE_URL + "/profile/";


export const JOBS_FOR_YOU_ROUTE = BASE_URL + "/profile/job-for-you"

export const BROWSE_JOBS =  BASE_URL + "/jobs"
export const EMPLOYER_LOGIN = BASE_URL + "/recruitment"

export const LANDING_PAGE_ROUTE = "/courses";
export const FEEDBACK_PAGE_ROUTE = "/courses/feedback/:id";
export const BROWSE_COURSES = "/courses/browse-courses";
export const COURSE_DETAIL_PAGE_ROUTE = "/courses/course-detail/:name/:id"
export const COURSE_CHAPTER_PAGE_ROUTE = "/courses/course-continue/:id"
export const LMS = "/courses"