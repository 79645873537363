import React, { Component } from "react";
import { SyncLoader } from "react-spinners";
export default class LoadingAnimation extends Component {
  render() {
    const style = {
      position: "absolute",
      display: "flex",
      justifyContent: "center",

      top: "40vh",
      bottom: 0,
      left: 0,
      right: 0,      
    };

    return (
      <SyncLoader css={style} sizeUnit={"px"} size={15} color={"#ffa400"} />
    );
  }
}
