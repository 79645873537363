import React, { Component } from "react";
import { BANNER_LAYOUT } from "../../constants";
import "../../sass/components/large-banner.scss";


export default class LargeBanner extends Component {    
  render() {
    return <>
      <div className="row large-banner text-white text-center">
        <div className="col-12 d-flex flex-column justify-content-center">
          <div className="text-size-1" dangerouslySetInnerHTML={{
            __html : this.props.heading
          }}></div>

          <div className="text-size-5">{this.props.subheading}</div>
        </div>
        
      </div>
    </>
  }      
}
