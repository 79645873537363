import React, { Component } from "react";
import { Route, Switch, Redirect, Router } from "react-router-dom";
import BrowseCourses from "../pages/browse-courses/BrowseCourses";
import CourseChapterPage from "../pages/course-chapter/CourseChapterPage";
import CourseDetailPage from "../pages/course-detail/CourseDetailPage";
import FeedbackPage from "../pages/feedback/FeedbackPage";
import HomePage from "../pages/home/HomePage";
import { BROWSE_COURSES, COURSE_CHAPTER_PAGE_ROUTE, COURSE_DETAIL_PAGE_ROUTE, FEEDBACK_PAGE_ROUTE, LANDING_PAGE_ROUTE } from "../routes";



export default class MainRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={LANDING_PAGE_ROUTE} component={HomePage} />
        <Route exact path={FEEDBACK_PAGE_ROUTE} component={FeedbackPage} />
        <Route exact path={COURSE_DETAIL_PAGE_ROUTE} component={CourseDetailPage} />
        <Route exact path={BROWSE_COURSES} component={BrowseCourses} />
        <Route exact path={COURSE_CHAPTER_PAGE_ROUTE} component={CourseChapterPage} />
        <Redirect from="/*" to={LANDING_PAGE_ROUTE} />
      </Switch>
    );
  }
}
