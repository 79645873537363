import React, { Component } from "react";
import "../../sass/form_components/standard-select.scss";
import PropTypes from "prop-types";
import Select from "react-select";
import classNames from "classnames";
import validationUtils from "../../utils/validationUtils";
import { colors } from "../../constants";
import _ from "lodash";
class StandardSelect extends Component {
  constructor(props){
    super(props);
    this.state ={
      valid : false,
      errorText : "",
      dirty : false
    }
  }

  componentDidMount(){
    this.checkValidatity(this.props.value);
  }
  renderLabel = () => {
    const { label } = this.props;
    if (label) {
      return <label className="font-weight-bold mb-2">{label}</label>;
    }
    return null;
  };

  renderError = () => {
    const { errorText } = this.state;

    if (errorText && (this.state.dirty || this.props.showError)) {
      return <p className="error-text mb-0 mt-2">{errorText}</p>;
    }
    return null;
  };

  checkValidatity = (value) => {    
    let isValid = true;
    let errorText = "";
    for(let validation of this.props.validations || []){            
      if(!validation.disabled && !validationUtils[validation.type](value,validation.value)){
        errorText  = validation.message;
        isValid = false;
        break;
      }
    }    
    this.setState({
      valid : isValid,
      errorText : errorText,      
    })
  }

  onChange = (e) => {     
    if(e)   
      this.checkValidatity(e.value);
    else{
      this.checkValidatity();
    }
    this.props.onChange(e);
  }

  onBlur = () => {         
    this.setState({
      dirty : true
    })
  }

  getSelectedOption = () => {      
    let selectedOption;
    for(let option of this.props.options){
      if(option.value == this.props.value){
        selectedOption = option;
      }
    }    
    return selectedOption ? selectedOption : null;
  };



  render() {
    const customStyles = {
      control: base => ({
        ...base,
        height: "3rem",
        borderColor : (this.state.dirty || this.props.showError) && !this.state.valid? colors["giraffe-danger"] : colors["giraffe-light"],
        '&:hover': {
          borderColor: colors["giraffe-primary"]
       },
       '&:focus': {
        borderColor: colors["giraffe-primary"]
     }
      })
    };
    const { props } = this;

    const style = classNames("standard-select", {
      [props.className]: props.className,
      error : (this.state.dirty || this.props.showError) && !this.state.valid
    });

    return (
      <div className={style}>
        {this.renderLabel()}
        <Select
        width="200px"
          placeholder={props.placeholder}
          isDisabled={props.isDisabled}
          options={props.options}
          onChange={this.onChange}
          value={this.getSelectedOption()}
          defaultValue={props.defaultValue}
          isClearable={props.isClearable}
          isSearchable={props.isSearchable}
          styles={customStyles}
          onBlur={this.onBlur}
        />
        {this.state.valid ? null : this.renderError()}
      </div>
    );
  }
}

StandardSelect.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  isClearable: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,  
  isSearchable: PropTypes.bool,
  className: PropTypes.string,
  errorText: PropTypes.string
};

StandardSelect.defaultProps = {
  isDisabled: false,
  isClearable: false,
  isSearchable: false
};

export default StandardSelect;
