import React, { Component } from "react";
import { CONTENT_LAYOUT } from "../../constants";
import "../../sass/components/breadcrumb.scss";


export default class BreadCrumb extends Component {    
  render() {
    return <>
      <div className="row breadcrumb no-gutters">
          <div className={CONTENT_LAYOUT + " justify-content-between d-flex"}>
            <div className="text-size-5 d-inline">{this.props.children}</div>
          </div>
        
      </div>
    </>
  }      
}
