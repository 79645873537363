export const BOOTSTRAP_APP_COLUMN_LAYOUT =
  "col-md-12";
export const HEADER_LAYOUT =
  "col-12 col-xl-10 offset-xl-1";

  export const BANNER_LAYOUT =
  "col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2";
  export const CONTENT_LAYOUT = "col-12 col-lg-10 col-xl-8 offset-xl-2 offset-lg-1";
  export const READING_LAYOUT = "col-12 col-lg-8 col-xl-6 offset-xl-3 offset-lg-2";

export const LOGIN_LAYOUT = "col-12 col-md-6 offset-md-3 col-xl-4 offset-xl-4";
export const API_URL = "http://giraffe-js-api.iconflux.info";
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const colors = {
  "primary-color": "#ffa400",
  "giraffe-success": "#00a860",
  "giraffe-primary": "#1b00b0",
  "giraffe-danger": "#f45b05",
  "giraffe-dark": "#2b2a2a",
  "giraffe-light": "#b5cded",
  "giraffe-secondary": "#c3bbad",
  "giraffe-background": "lightgrey",
};
