import React, { Component } from "react";
import "../../sass/form_components/standard-button.scss";
import PropTypes from "prop-types";
import classNames from "classnames";

class StandardButton extends Component {
  getStandardButtonClassNames = () => {
    const {
      disabled,
      block,
      color,
      size,
      className,
      bold,
      border
    } = this.props;    
    return classNames("standard-button", "btn", {
      disabled: disabled,
      "btn-block": block,
      "font-weight-bold": bold,
      "border-less": !border,
      [color]: color,
      [size]: size,
      [className]: className
    });
  };

  render() {
    const { onClick, disabled, text } = this.props;
    return (
      <button
        className={this.getStandardButtonClassNames()}
        type="button"        
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </button>
    );
  }
}

StandardButton.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
  size: PropTypes.string,
  bold: PropTypes.bool,
  className: PropTypes.string,
  border: PropTypes.bool
};

StandardButton.defaultProps = {
  disabled: false,
  block: false,  
  bold : true,  
  border: true
};

export default StandardButton;
