import React, { Component } from "react";
import { CONTENT_LAYOUT } from "../../constants";
import "../../sass/components/banner.scss";


export default class Banner extends Component {    
  render() {
    return <>
      <div className="row banner text-white">
          <div className={CONTENT_LAYOUT + " justify-content-between d-flex flex-column flex-md-row"}>
            <div className="text-size-2 d-md-inline mt-auto mb-auto" dangerouslySetInnerHTML={{
            __html : this.props.heading
            }}></div>

            <div className="text-size-5 d-md-inline float-md-right mb-auto">{this.props.children}</div>
          </div>
        
      </div>
    </>
  }      
}
