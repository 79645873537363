import React, { Component } from "react";
import {  
  HEADER_LAYOUT,
} from "../../constants";
import {
  BROWSE_JOBS,  
  JOBS_FOR_YOU_ROUTE,    
} from "../../routes";
import "../../sass/components/footer.scss";
import StandardButton from "../form_components/StandardButton";

export default class Footer extends Component {
  commonMenuItems = [
    {
      title: (
        <>
          <img className="edit-icon" src="/courses/assets/icons/job.svg" alt=""></img>
          <span className="ml-2">Jobs for you</span>
        </>
      ),
      classes: "border-0 btn-outline-dark",
      icon: "/courses/assets/icons/job.svg",
      link: JOBS_FOR_YOU_ROUTE,
    },
    {
      title: (
        <>
          <img
            className="edit-icon"
            src="/courses/assets/icons/browse-jobs.png"
            alt=""
          ></img>
          <span className="ml-2">Browse Jobs</span>
        </>
      ),
      classes: "border-0 btn-outline-dark",
      icon: "/courses/assets/icons/browse-jobs.svg",
      link: BROWSE_JOBS,
    },
  ];  

  renderMenu() {
    let menuItems = this.commonMenuItems;    
    return menuItems.map((item, index) => {
      let classes = "ml-3 rounded";
      if (item.classes) {
        classes += " " + item.classes;
      }
      return (
        <StandardButton
          key={index}
          className={classes}
          size="btn-sm"
          text={item.title}
          onClick={() => this.openPage(item)}
        />
      );
    });
  }

  openPage(item) {
    if (item.function) {
      item.function();
    }
    if (item.link) {      
      window.location = item.link;
    }
  }
  render() {
    return (
      <div className="col-md-12 footer">
        <div className="row no-gutters">
          <div id="footerDiv" className={HEADER_LAYOUT}>
            <div className="justify-content-between text-center">
              <div className="text-size-5 text-bold">
                {/* <span className="text-blue">Privacy &amp; Terms</span> Giraffe ©
                Copyright {new Date().getFullYear()}. All rights reserved. */}                
                This work is licensed under a{" "}
                <a
                  rel="license"
                  href="http://creativecommons.org/licenses/by-nc/4.0/"
                >
                  Creative Commons Attribution-NonCommercial 4.0 International
                  License
                </a>
                <a
                  rel="license"
                  href="http://creativecommons.org/licenses/by-nc/4.0/"                  
                >
                  <img
                  className="ml-3"
                    alt="Creative Commons License"
                    style={{borderWidth:0}}
                    src="https://i.creativecommons.org/l/by-nc/4.0/80x15.png"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
