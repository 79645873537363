import React, { Component } from "react";
import "../../sass/pages/feedback-page.scss";
import { CONTENT_LAYOUT, READING_LAYOUT } from "../../constants";
import StandardButton from "../../components/form_components/StandardButton";
import BrowseCategory from "../home/components/BrowseCategory";
import sendRequest from "../../utils/ajaxUtils";
import LoadingAnimation from "../../components/common_components/LoadingAnimation";
import StandardTextArea from "../../components/form_components/StandardTextArea";
import showNotification from "../../utils/notificationUtils";
import { BROWSE_COURSES, COURSE_DETAIL_PAGE_ROUTE } from "../../routes";
import StandardInput from "../../components/form_components/StandardInput";

class FeedbackPage extends Component {
  state = {
    fetchDataComplete: false,
    feedbackGiven: false,
    rating: 0,
    review: "",
  };
  componentDidMount() {
    let courseUrl = "public/course/" + this.props.match.params.id;
    Promise.all([
      sendRequest({
        type: "get",
        url: courseUrl,
      }),
      sendRequest({
        type: "get",
        url: "public/category",
      }),
    ])
      .then((data) => {
        this.setState({
          courseDetail: data[0],
          categories: data[1],
          fetchDataComplete: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  renderTopSection() {
    if (!this.state.feedbackGiven) {
      return (
        <div className="row pt-5 feedback-section">
          <div className="col-12 col-lg-6 text-center d-block d-lg-none">
            <img
              className="section-img mt-3 w-100"
              src="/courses/assets/img/congrats.png"
              alt="Home"
            />
          </div>
          <div className="col-12 col-lg-6 text-center text-lg-left d-flex align-items-center">
            <div>
              <p className="text-size-2 font-weight-bolder text-white">
                Congrats, you're done!
              </p>
              <p className="text-size-4 text-white">
                You have successfully completed the "
                <span className="text-bold">
                  {this.state.courseDetail.name}
                </span>
                " course - well done!
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6 text-center d-none d-lg-block">
            <img
              className="section-img mt-3 w-100"
              src="/courses/assets/img/congrats.png"
              alt="Home"
            />
          </div>
        </div>
      );
    }
    let title = `I just finished this course- ${this.state.courseDetail.name}. It was really useful, you should check it out`;
    let url =
      window.location.protocol +
      "//" +
      window.location.hostname +
      COURSE_DETAIL_PAGE_ROUTE.replace(
        ":id",
        this.state.courseDetail.id
      ).replace(
        ":name",
        this.state.courseDetail.name.toLowerCase().replace(/ /g, "-")
      );
    let whatsappLink = `whatsapp://send?text=${title}%0a%0a${url}?channel=washare`;
    let facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${url}?channel=fbshare&t=${title}`;
    return (
      <div className="row pt-5 feedback-section">
        <div className="col-12 col-lg-6 text-center">
          <h2 className="text-size-2">Thanks for your feedback</h2>
          <img
            className="section-img mt-3 w-100"
            src="/courses/assets/img/feedback_image.png"
            alt="Home"
          />
        </div>
        <div className="col-12 col-lg-6 text-center text-lg-left m-auto">
          <h2 className="text-size-3">Tell your friends about this course!</h2>
          <a
            href={facebookLink}
            target="_blank"
            without
            rel="noopener noreferrer"
          >
            <StandardButton
              className="cta-button fb-button mt-3 mr-lg-3"
              color="btn-secondary"
              bold={true}
              block={false}
              text={
                <>
                  <i class="fab fa-facebook-f mr-3"></i>
                  Share on Facebook
                </>
              }
            />
          </a>
          <a
            href={whatsappLink}
            target="_blank"
            without
            rel="noopener noreferrer"
          >
            <StandardButton
              className="cta-button whatsapp-button mt-3"
              color="btn-secondary"
              bold={true}
              block={false}
              text={
                <>
                  <i class="fab fa-whatsapp mr-3"></i>
                  Share on whatsapp
                </>
              }
            />
          </a>
        </div>
      </div>
    );
  }

  redirectToBrowseCourses = () => {
    this.props.history.push(BROWSE_COURSES);
  };

  renderCompleted() {
    return (
      <div className="feedback-completed">
        <div className="row bg-white">
          <div className={CONTENT_LAYOUT + " pb-5 feedback-section"}>
            {this.renderTopSection()}
          </div>
        </div>
        <div className="row bg-white category-bg">
          <div className={CONTENT_LAYOUT + ""}>
            <BrowseCategory
              categories={this.state.categories}
              history={this.props.history}
            />
            <div className="text-center mb-5">
              <StandardButton
                className="cta-button"
                color="btn-primary"
                bold={true}
                block={false}
                text="Browse all courses"
                onClick={this.redirectToBrowseCourses}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFeedbackImages() {
    let feedbackImages = [];
    for (let i = 1; i <= 5; i++) {
      if (i === this.state.rating) {
        feedbackImages.push(
          <div
            className="smiley-container"
            onClick={() => this.setState({ rating: i })}
          >
            <img
              src={`/courses/assets/img/feedback_smiley_${i}_on.png`}
              alt=""
            />
          </div>
        );
      } else {
        feedbackImages.push(
          <div
            className="smiley-container"
            onClick={() => this.setState({ rating: i })}
          >
            <img src={`/courses/assets/img/feedback_smiley_${i}.png`} alt="" />
          </div>
        );
      }
    }

    return (
      <>
        <div className="col-12 text-center my-3">
          <h1 className="text-size-2">Did you like this course?</h1>
        </div>
        <div className="col-12 col-lg-8 offset-lg-2 d-flex p-2 justify-content-around smiley-col mt-3">
          {feedbackImages}
        </div>
      </>
    );
  }

  updateTextValue = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  submit = () => {
    console.log("Comes here?");
    if (!this.state.rating) {
      return showNotification("Please select a rating", { type: "error" });
    }
    if (!this.state.email) {
      return showNotification("Please add your email", { type: "error" });
    }
    this.setState({
      fetchDataComplete: false,
    });
    let courseUrl =
      "public/course/" +
      this.props.match.params.id +
      "/action/review";
    sendRequest({
      type: "post",
      url: courseUrl,
      data: {
        rating: this.state.rating,
        review: this.state.review,
        email : this.state.email
      },
    })
      .then(() => {
        this.setState({
          feedbackGiven: true,
          fetchDataComplete: true,
        });
      })
      .catch((err) => {
        this.setState({
          fetchDataComplete: true,
        });
        showNotification("Error posting your feedback", { type: "error" });
      });
  };

  renderRateSection() {
    return (
      <div className="feedback-started">
        <div className="row bg-white">
          <div className={READING_LAYOUT}>
            {this.renderTopSection()}
            {this.renderFeedbackImages()}
            <div className="col-12 mt-3 px-0 text-center text-md-left">
            <StandardInput
                label="Email Address"
                value={this.state.email}
                onChange={(e) => this.updateTextValue("email", e.target.value)}
                rows={4}
              ></StandardInput>
              <StandardTextArea
                label="What did you think about this course?"
                value={this.state.review}
                onChange={(value) => this.updateTextValue("review", value)}
                rows={4}
              ></StandardTextArea>
              <StandardButton
                className="cta-button mb-2 my-auto my-md-0"
                color="btn-primary"
                bold={true}
                block={false}
                text="Submit"
                onClick={this.submit}
              />
            </div>
          </div>
        </div>
        <div className="row bg-white">
          <div className={READING_LAYOUT}>
            <div className="row"></div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    if (!this.state.fetchDataComplete) {
      return <LoadingAnimation />;
    } else if (this.state.feedbackGiven) {
      return this.renderCompleted();
    } else {
      return this.renderRateSection();
    }
  }
}

export default FeedbackPage;
