import React, { Component } from "react";
import "../../sass/form_components/standard-textarea.scss";
import classNames from "classnames";
import PropTypes from "prop-types";
import uuid from "uuid";
import validationUtils from "../../utils/validationUtils";

class StandardTextArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textAreaId: uuid.v4(),
      valid : false,
      errorText : "",
      dirty : false
    };
  }

  componentDidMount(){
    this.checkValidatity(this.props.value);
  }

  getStandardTextAreaClass = () => {
    const { disabled, className } = this.props;
    return classNames("standard-textarea", "form-group", {
      disabled: disabled,
      [className]: className,
      error : (this.state.dirty || this.props.showError) && !this.state.valid
    });
  };

  getTextAreaClass = () => {    
    return classNames("form-control", {
      error: (this.state.dirty || this.props.showError) && !this.state.valid
    });
  };

  renderLabel = () => {
    const { textAreaId } = this.state;
    const { label } = this.props;

    if (label) {
      return (
        <label className="font-weight-bold mb-2" htmlFor={textAreaId}>
          {label}
        </label>
      );
    }
    return null;
  };

  renderHelp = () => {
    const { helpText } = this.props;

    if (helpText) {
      return <p className="help-text mb-0 mt-2">{helpText}</p>;
    }
    return null;
  };

  renderError = () => {
    const { errorText } = this.state;

    if (errorText && (this.state.dirty || this.props.showError)) {
      return <p className="error-text mb-0 mt-2">{errorText}</p>;
    }
    return null;
  };

  checkValidatity = (value) => {    
    let isValid = true;
    let errorText = "";
    for(let validation of this.props.validations || []){            
      if(!validation.disabled && !validationUtils[validation.type](value,validation.value)){
        errorText  = validation.message;
        isValid = false;
        break;
      }
    }    
    this.setState({
      valid : isValid,
      errorText : errorText,      
    })
  }

  onChange = (e) => {    
    this.checkValidatity(e.target.value);
    this.props.onChange(e.target.value);
  }

  onBlur = () => {        
    this.setState({
      dirty : true
    })
  }


  render() {
    const { textAreaId } = this.state;
    const { placeholder, disabled, value, rows } = this.props;

    return (
      <div className={this.getStandardTextAreaClass()}>
        {this.renderLabel()}
        <textarea
          type="text"
          className={this.getTextAreaClass()}
          id={textAreaId}
          placeholder={placeholder}
          disabled={disabled}
          value={value || ""}
          onChange={this.onChange}
          rows={rows}
        />

      {this.state.valid ? this.renderHelp() : this.renderError()}
      </div>
    );
  }
}

StandardTextArea.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,  
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  helpText: PropTypes.string,
  errorText: PropTypes.string,
  error: PropTypes.bool,
  rows: PropTypes.number
};

StandardTextArea.defaultProps = {
  disabled: false,
  error: false,
  placeholder: "",
  value: "",
  rows: 5
};

export default StandardTextArea;
