import React, { Component } from "react";
import "../../sass/pages/course-detail-page.scss";
import { READING_LAYOUT } from "../../constants";
import StandardButton from "../../components/form_components/StandardButton";
import LargeBanner from "../../components/common_components/LargeBanner";
import LoadingAnimation from "../../components/common_components/LoadingAnimation";
import moment from "moment";
import sendRequest from "../../utils/ajaxUtils";
import {
  BROWSE_COURSES,
  COURSE_CHAPTER_PAGE_ROUTE,
  LANDING_PAGE_ROUTE,  
} from "../../routes";
import { Link } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
class CourseDetailPage extends Component {
  state = {
    fetchDataComplete: false,
  };
  componentDidMount() {
    let courseUrl = "public/course/" + this.props.match.params.id;
    Promise.all([
      sendRequest({
        type: "get",
        url: courseUrl,
      }),
    ])
      .then((data) => {
        this.setState({
          courseDetail: data[0],
          fetchDataComplete: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  renderChapters(chapters) {
    return chapters.sort((a,b) => {
      return a.chronology - b.chronology
    }).map((chapter) => {
      return (
        <div className="col-12 col-md-6 mt-3">
          <div className="chapter d-flex">
            <div className="chapter-number text-size-5 align-self-center">
              {chapter.chronology}
            </div>
            <div className="text-size-5 text-bold align-self-center">{chapter.name}</div>
          </div>
        </div>
      );
    });
  }

  startCourse = async () => {
    this.props.history.push(
      COURSE_CHAPTER_PAGE_ROUTE.replace(
        ":id",
        this.props.match.params.id
      )
    );    
  };
  renderCourseDetail() {
    const { courseDetail } = this.state;
    let buttonText = "Start Course";    
    let title = `This course looks interesting, you should check it out`;
    let url = window.location.href;
    let whatsappLink =
        `whatsapp://send?text=${title}%0a%0a${url}?channel=washare`    
    let linkedinLink = `https://www.linkedin.com/sharing/share-offsite/?url=${url}?channel=lishare&title=${title}`;
    let facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${url}?channel=fbshare&t=${title}`;
    return (
      <div className="row mt-5 course-section no-gutters ">
        <div
          className="col-12 course-image d-flex align-content-between flex-column"
          style={{
            backgroundImage: `url('${courseDetail.file_url}')`,
          }}
        >          
          <div className="row no-gutters mb-3 mt-auto">
            <div className="category" style={{backgroundColor : courseDetail.category_color}}>
              <div className="text-size-4 text-bold d-inline ml-3 mr-3">
                {courseDetail.category_name}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 px-3 text-bold text-size-3 mt-3">
          {courseDetail.name}
        </div>
        <div className="col-12 pr-3 pl-3 stats">
          <div className="d-inline">
            <img
              src="/courses/assets/img/like.png"
              alt=""
              className="content-img"
            />{" "}
            <span className="text-size-5">{courseDetail.likes} likes</span>
          </div>          
          <div className="d-inline ml-2">
            <img
              src="/courses/assets/img/time.png"
              alt=""
              className="content-img"
            />{" "}
            <span className="text-size-5">
              {courseDetail.timeToRead} Minutes
            </span>
          </div>
          <div className="d-inline ml-2">
            <img
              src="/courses/assets/img/date.png"
              alt=""
              className="content-img"
            />{" "}
            <span className="text-size-5">
              {moment(courseDetail.publishedDate).format("MMM YYYY")}
            </span>
          </div>
          <div className="float-right d-none d-lg-inline">
            <a href={facebookLink}><i class="fab fa-facebook-f mr-3"></i></a>
            <a href={whatsappLink}><i class="fab fa-whatsapp mr-3"></i></a>
            <a href={linkedinLink}><i class="fab fa-linkedin"></i></a>            
          </div>
          <hr />
        </div>
        <div className="col-12 mt-3 pr-3 pl-3">
          <div className="text-size-4 text-bold">Overview</div>
          <div className="text-size-5 description">{courseDetail.overview}</div>
        </div>
        <div className="col-12 mt-3 pr-3 pl-3">
          <div className="text-size-4 text-bold">
            Sections in this course
          </div>
          <div className="row">
            {this.renderChapters(courseDetail.chapters)}
          </div>          
          <div className="d-flex justify-content-between">
          <div>
          <StandardButton
                  className="cta-button mt-3 start-button"
                  color="btn-primary"
                  bold={true}
                  block={false}
                  text={buttonText}
                  onClick={this.startCourse}
                />
          </div>
          <div className="d-flex d-lg-none align-self-center pt-3">
            <a href={facebookLink}><i class="fab fa-facebook-f mr-3"></i></a>
            <a href={whatsappLink}><i class="fab fa-whatsapp mr-3"></i></a>
            <a href={linkedinLink}><i class="fab fa-linkedin"></i></a>            
          </div>
          </div>
          
        </div>
      </div>
    );
  }
  render() {
    
    if (!this.state.fetchDataComplete) {
      return <LoadingAnimation />;
    }
    
    return (
      <div className="course-detail">
        <MetaTags>
          <title>Giraffe Free Course {this.state.courseDetail.name}</title>
          <meta
            name="description"
            content={`Take this free course on ${this.state.courseDetail.name}. Enhance your career and life skills by taking this course on <<course title>>`}
          />
        </MetaTags>
        <div className="row">
          <div className="col-12">
            <LargeBanner
              heading={this.state.courseDetail.name}
              subheading={
                <>
                  <Link to={LANDING_PAGE_ROUTE}>Home</Link> /{" "}
                  <Link to={BROWSE_COURSES}>Courses</Link>/{" "}
                  <span className="text-bold">
                    {this.state.courseDetail.name}
                  </span>
                </>
              }
            />
          </div>
        </div>

        <div className="row bg-white">
          <div className={READING_LAYOUT + " pb-5"}>
            {this.renderCourseDetail()}
          </div>
        </div>
      </div>
    );
  }
}

export default CourseDetailPage;
