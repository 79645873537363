import React, { Component } from "react";
import "../../sass/pages/home-page.scss";
import { CONTENT_LAYOUT, HEADER_LAYOUT } from "../../constants";
import StandardButton from "../../components/form_components/StandardButton";
import BrowseCategory from "./components/BrowseCategory";
import WhyGiraffe from "./components/WhyGiraffe";
import StandardInput from "../../components/form_components/StandardInput";
import { BROWSE_COURSES } from "../../routes";
import sendRequest from "../../utils/ajaxUtils";
import LoadingAnimation from "../../components/common_components/LoadingAnimation";
import MetaTags from "react-meta-tags";

class HomePage extends Component {
  state = {
    fetchDataComplete: false,
  };
  componentDidMount = () => {
    let requests = [
      sendRequest({
        type: "get",
        url: "public/category",
      }),
    ];
    Promise.all(requests)
      .then((data) => {
        this.setState({
          categories: data[0],
          fetchDataComplete: true,          
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  redirectToBrowseCourses = () => {
    this.props.history.push(BROWSE_COURSES);
  };
  renderTopSection() {
    return (
      <div className="row mt-5">
        <div className="col-12 col-lg-6 d-block d-lg-none mb-3 text-center">
          <img
            className="section-img"
            src="/courses/assets/img/header_image.png"
            alt="Home"
          />
        </div>
        <div className="col-10 offset-1 offset-lg-0 col-lg-6 text-center text-lg-left align-self-center">
          <h1 className="text-size-1 mb-3">
            Free online courses to help you get a job
          </h1>
          <p className="text-size-4">
            We know that it’s tough being a jobseeker and it gets overwhelming.
            It may feel like you’re lost in a jungle, but Giraffe is here to
            guide you safely through.
          </p>
          <p className="text-size-4">
            Giraffe’s free online courses help you develop your skills, become
            more employable and manage your money properly
          </p>
          <StandardButton
            className="cta-button text-bold"
            color="btn-primary"
            bold={true}
            block={false}
            text="Browse all courses"
            onClick={this.redirectToBrowseCourses}
          />
        </div>
        <div className="col-12 col-lg-6 d-none d-lg-flex text-center pt-lg-5">
          <img
            className="section-img align-self-center"
            src="/courses/assets/img/header_image.png"
            alt="Home"
          />
        </div>
      </div>
    );
  }
  renderBoostSection() {
    return (
      <div className="row mt-5">
        <div className="col-12 col-lg-6 p-5 d-none d-lg-flex">
          <img
            className="section-img align-self-center"
            src="/courses/assets/img/boost.png"
            alt="Home"
          />
        </div>
        <div className="col-12 col-lg-6 offset-lg-0 align-self-center">
          <div className="text-size-2 text-center text-lg-left mb-4 mb-lg-0">
            Want to <span className="text-orange">boost your career?</span> Get
            the best jobs and courses with Giraffe
          </div>
          <div className="col-12 d-block d-lg-none text-center mb-4">
            <img
              className="section-img"
              src="/courses/assets/img/boost.png"
              alt="Home"
            />
          </div>
          <div className="d-flex mt-3">
            <div className="align-self-center boost-img">
              <img src="/courses/assets/img/vodacom-mtn.png" alt="Section" />
            </div>
            <div className="text-size-4 text-bold ml-3">
              Register for FREE anywhere using your phone- no data charges on
              Vodacom or MTN
            </div>
          </div>
          <div className="d-flex mt-3 ">
            <div className="align-self-center boost-img">
              <img src="/courses/assets/img/area.png" alt="Section" />
            </div>
            <div className="text-size-4 text-bold ml-3">
              See the best jobs in your area, based on your experience
            </div>
          </div>
          <div className="d-flex mt-3 ">
            <div className="align-self-center boost-img">
              <img src="/courses/assets/img/wealth.png" alt="Section" />
            </div>
            <div className="text-size-4 text-bold ml-3">
              Take courses that help you get a better job and grow your wealth
            </div>
          </div>
        </div>
        <div className="col-12 text-center mt-4">
          <StandardButton
            className="cta-button"
            color="btn-primary"
            bold={true}
            block={false}
            text="Sign Up"
          />
        </div>
      </div>
    );
  }

  renderSubscriptionSection() {
    return (
      <div className="pt-5 text-center">
        <div className="text-size-2">Subscribe Newsletters</div>
        <div className="text-size-5 mt-3">
          Enter your email address to register to our newsletter subscription
          delivered on a regular basis!
        </div>
        <div className="sub-input mt-4">
          <StandardInput placeholder="Enter your email" />
          <StandardButton
            className="cta-button d-inline"
            color="btn-secondary"
            bold={true}
            block={false}
            text="Subscribe"
          />
        </div>
      </div>
    );
  }
  render() {
    if (!this.state.fetchDataComplete) {
      return <LoadingAnimation />;
    }
    return (
      <div className="home-page">
        <MetaTags>
          <title>Giraffe - Free Online Courses To Help You Get A Job</title>
          <meta
            name="description"
            content="Giraffe’s free online courses help you develop your skills, become more employable and manage your money properly"
          />
        </MetaTags>
        <div className="row header-bg">
          <div className={CONTENT_LAYOUT + " pb-5"}>
            {this.renderTopSection()}            
          </div>
        </div>
        <div className="row bg-white category-bg">
          <div className={CONTENT_LAYOUT + " pb-5"}>
            <BrowseCategory
              categories={this.state.categories}
              history={this.props.history}
            />
          </div>
        </div>
        <div className="row">
          <div className={CONTENT_LAYOUT + " pb-5"}>
            <WhyGiraffe history={this.props.history} />
          </div>
        </div>
        <div className="row bg-white">
          <div className={CONTENT_LAYOUT + " pb-5"}>
            {this.renderBoostSection()}
          </div>
        </div>
        {/* <div className="row bg-white text-white subscription-section">
                <div className={CONTENT_LAYOUT + " pb-5"}>
                    {this.renderSubscriptionSection()}
                </div>
            </div> */}
      </div>
    );
  }
}

export default HomePage;
