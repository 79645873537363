import React, { Component } from "react";
import "../../sass/pages/browse-courses.scss";
import { CONTENT_LAYOUT } from "../../constants";
import StandardButton from "../../components/form_components/StandardButton";
import LargeBanner from "../../components/common_components/LargeBanner";
import StandardSelect from "../../components/form_components/StandardSelect";
import sendRequest from "../../utils/ajaxUtils";
import { isMobile, convertArrayToDropdown } from "../../utils/generalUtils";
import courseSortOptions from "../../dropdowns/courseSortOptions";
import moment from "moment";
import queryString from "query-string";
import {
  BROWSE_COURSES,
  COURSE_DETAIL_PAGE_ROUTE,
  LANDING_PAGE_ROUTE,
} from "../../routes";
import LoadingAnimation from "../../components/common_components/LoadingAnimation";
import courseSortEnum from "../../enums/courseSortEnum";
import _ from "lodash";
import { Link } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
class BrowseCourses extends Component {
  constructor(props) {
    super(props);
    let params = queryString.parse(this.props.location.search);
    this.state = {
      courses: [],
      categories: [],
      fetchDataComplete: false,
      filterOpen: isMobile() ? false : true,
      selectedCategories: params.category,
      sortBy: params.sortBy || courseSortEnum.CHRONOLOGICAL,      
    };
  }

  componentDidMount = () => {
    let courseUrl = "public/course";
    if (this.state.selectedCategories) {
      courseUrl += "?category_id=" + this.state.selectedCategories;
    }
    let requests = [
      sendRequest({
        type: "get",
        url: "public/category",
      }),
      sendRequest({
        type: "get",
        url: courseUrl,
      }),
    ];    
    Promise.all(requests)
      .then((data) => {
        for (let course of data[1]) {
          course.publishedDate = new Date(course.publishedDate);
        }
        this.setState({
          categories: convertArrayToDropdown(data[0]),
          courses: this.sortCourses(data[1]),
          fetchDataComplete: true,          
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidUpdate() {
    let params = queryString.parse(this.props.location.search);
    if (params.category !== this.state.selectedCategories) {
      // this.setState({
      //     fetchDataComplete : false
      // })
      let courseUrl = "public/course";
      if (params.category) {
        courseUrl += "?category_id=" + params.category;
      }
      Promise.all([
        sendRequest({
          type: "get",
          url: courseUrl,
        }),
      ])
        .then((data) => {
          for (let course of data[0]) {
            course.publishedDate = new Date(course.publishedDate);
          }
          this.setState({
            selectedCategories: params.category,
            courses: this.sortCourses(data[0]),
            fetchDataComplete: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (params.sortBy != this.state.sortBy) {
      this.setState({
        courses: this.sortCourses(this.state.courses, params.sortBy),
        sortBy: params.sortBy,
      });
    }
  }

  sortCourses(courses, sortBy) {
    if (!sortBy) {
      sortBy = this.state.sortBy;
    }
    if (sortBy) {
      if (sortBy == courseSortEnum.RECENTLY_ADDED) {
        return _.orderBy(courses, ["publishedDate"], ["desc"]);
      } else if (sortBy == courseSortEnum.MOST_POPULAR) {
        return _.orderBy(courses, ["likes"], ["desc"]);
      } else if (sortBy == courseSortEnum.CHRONOLOGICAL) {
        return _.orderBy(courses, ["id"], ["asc"]);
      }
    } else {
      return courses;
    }
  }

  renderChapters(chapters) {
    return chapters.sort((a,b) => {
      return a.chronology - b.chronology
    }).map((chapter) => {
      return (
        <div className="col-12 mt-1 p-0">
          <div className="chapter d-flex">
            <div className="chapter-number align-self-center">
              {chapter.chronology}
            </div>
            <div className="text-bold align-self-center">{chapter.name}</div>
          </div>
        </div>
      );
    });
  }

  updateExtendCourse(index) {
    let courses = this.state.courses;
    courses[index].showMore = !courses[index].showMore;
    this.setState({
      courses: courses,
    });
  }

  goToCourse = (course) => {
    this.props.history.push(COURSE_DETAIL_PAGE_ROUTE.replace(":id", course.id).replace(":name",course.name.toLowerCase().replace(/ /g, "-")));
  };

  renderCourses() {
    return this.state.courses.map((course, index) => {
      let showMoreButtonText;
      let title = `This course looks interesting, you should check it out`;
      let url = window.location.protocol + "//" + window.location.hostname + COURSE_DETAIL_PAGE_ROUTE.replace(":id", course.id).replace(":name",course.name.toLowerCase().replace(/ /g, "-"));
      let whatsappLink =
        `whatsapp://send?text=${title}%0a%0a${url}?channel=washare`
      let linkedinLink = `https://www.linkedin.com/sharing/share-offsite/?url=${url}?channel=lishare&title=${title}`;
      let facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${url}?channel=fbshare&t=${title}`;
      if (course.showMore) {
        showMoreButtonText = (
          <>
            <img src="/courses/assets/img/shrink.png" alt="" /> Less
          </>
        );
      } else {
        showMoreButtonText = (
          <>
            <img src="/courses/assets/img/expand.png" alt="" /> More
          </>
        );
      }
      return (
        <div className="col-12 col-md-6 mb-3">
          <div className="browse-course-detail">
            <div
              className="col-12 d-flex flex-column align-content-between course-image"
              style={{
                backgroundImage: `url('${course.file_url}')`,
              }}
            >
              <div className="row mb-3 mt-3">
                <div className="col-12 p-0">
                  <div className="d-inline">
                    <StandardButton
                      className="float-right view-more-less-btn mr-3"
                      color="btn-outline"
                      block={false}
                      text={showMoreButtonText}
                      onClick={() => this.updateExtendCourse(index)}
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-3 mt-auto">
                <div className="col-12 p-0">
                  <div className="category d-inline" style={{backgroundColor : course.category_color}}>
                    <div className="text-size-4 d-inline ml-3 mr-3 text-bold">
                      {course.category_name}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-3 stats">
              <div className="text-size-3">{course.name}</div>
              <div className="d-inline">
                <img
                  src="/courses/assets/img/like.png"
                  alt=""
                  className="content-img"
                />{" "}
                <span className="text-size-5">{course.likes} likes</span>
              </div>              
              <div className="d-inline ml-2">
                <img
                  src="/courses/assets/img/time.png"
                  alt=""
                  className="content-img"
                />{" "}
                <span className="text-size-5">{course.timeToRead} Minutes</span>
              </div>
              <div className="d-inline ml-2">
                <img
                  src="/courses/assets/img/date.png"
                  alt=""
                  className="content-img"
                />{" "}
                <span className="text-size-5">
                  {moment(course.publishedDate).format("MMM YYYY")}
                </span>
              </div>
              <hr />
            </div>
            <div className="col-12 mt-3">
              <div className="text-size-4 text-bold">Overview</div>
              <div className="text-size-5 description">{course.overview}</div>
            </div>
            {course.showMore ? (
              <div className="col-12 mt-3">
                <div className="text-size-4 text-bold">Sections in this course</div>
                {this.renderChapters(course.chapters)}
              </div>
            ) : null}
            <div className="col-12 mt-3">
              <StandardButton
                className="cta-button"
                color="btn-primary"
                bold={true}
                block={false}
                text="Enroll"
                onClick={() => this.goToCourse(course)}
              />
              <div className="float-right">
                <a href={facebookLink}>
                  <i class="fab fa-facebook-f mr-3"></i>
                </a>
                <a href={whatsappLink}>
                  <i class="fab fa-whatsapp mr-3"></i>
                </a>
                <a href={linkedinLink}>
                  <i class="fab fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  toggleFilter = () => {
    this.setState({
      filterOpen: !this.state.filterOpen,
    });
  };

  updateSelectedCategories = (value) => {
    this.setState({
      selectedCategories: value.value,
    });
    let query = {
      category: value.value,
      sortBy: this.state.sortBy,
    };
    this.props.history.push(
      BROWSE_COURSES + "?" + queryString.stringify(query)
    );
  };

  updateSelectedSortby = (value) => {
    let query = {
      sortBy: value.value,
      category: this.state.selectedCategories,
    };
    this.props.history.push(
      BROWSE_COURSES + "?" + queryString.stringify(query)
    );
  };

  clearFilters = () => {
    this.props.history.push(BROWSE_COURSES);
  };
  render() {
    if (!this.state.fetchDataComplete) {
      return <LoadingAnimation />;
    }
    return (
      <div className="browse-courses">
        <MetaTags>
          <title>Giraffe - Browse All Free Online Courses</title>
          <meta
            name="description"
            content="Browse all our free online courses- in finding a job, interview preparation, what career suits you, how to manage your money and improve your life"
          />
        </MetaTags>
        <div className="row">
          <div className="col-12">
            <LargeBanner
              heading="Courses"
              subheading={
                <>
                  <Link to={LANDING_PAGE_ROUTE}>Home</Link>
                  <span className="text-bold"> / Courses</span>
                </>
              }
            />
          </div>
        </div>

        <div className="row bg-white">
          <div className={CONTENT_LAYOUT + " pb-5"}>
            <div className="filter row no-gutters">
              <div
                className="col-12 p-3 d-block d-md-none"
                onClick={this.toggleFilter}
              >
                <div className="filter-toggle-btn">
                  <img
                    alt="filter-controller"
                    src="/courses/assets/img/filter.svg"
                    className="mr-3"
                  />
                  Courses Filter
                  {this.state.filterOpen ? (
                    <i className="fa fa-chevron-up float-right mt-1" />
                  ) : (
                    <i className="fa fa-chevron-down float-right mt-1" />
                  )}
                </div>
              </div>
              {this.state.filterOpen ? (
                <>
                  <div className="col-12 col-md-2  text-left text-md-center p-md-3 pl-3 pr-3">
                    <p className="text-size-5 text-bold"> Course Category</p>
                  </div>
                  <div className="col-12 col-md-3 py-md-3 px-md-0 px-3">
                    <StandardSelect
                      className="text-size-5"
                      placeholder="Select category"
                      value={this.state.selectedCategories}
                      options={this.state.categories}
                      onChange={this.updateSelectedCategories}
                    />
                  </div>
                  <div className="col-12 col-md-2 text-left text-md-center p-md-3 pl-3 pr-3">
                    <p className="text-size-5 text-bold"> Sort by</p>
                  </div>
                  <div className="col-12 col-md-3 py-md-3 px-md-0 px-3">
                    <StandardSelect
                      className="text-size-5"
                      placeholder="Select Sort"
                      value={this.state.sortBy}
                      options={courseSortOptions}
                      onChange={this.updateSelectedSortby}
                    />
                  </div>
                  <div className="col-12 col-md-2 p-md-3 pl-3 pr-3 text-center text-md-left">
                    <StandardButton
                      className="cta-button danger-text pl-0 bold text-bold text-size-5"
                      color="btn-outline"
                      bold={true}
                      block={false}
                      text="Clear all"
                      onClick={this.clearFilters}
                    />
                  </div>
                </>
              ) : null}
            </div>
            <div className="row mt-3">{this.renderCourses()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default BrowseCourses;
