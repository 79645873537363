import { slide as Menu } from 'react-burger-menu'
import React,{ Component } from 'react';
import "../../sass/components/sidebar.scss";
import TopNavigation from './TopNavigation';
import StandardButton from '../form_components/StandardButton';
import { JOBS_FOR_YOU_ROUTE, LOGIN_ROUTE, RETURNING_USER_ROUTE,BROWSE_JOBS, SIGN_UP_LANDING_ROUTE, EMPLOYER_LOGIN, LMS, BROWSE_COURSES } from '../../routes';


 
export default class Sidebar extends Component {
  
  commonMenuItems = [{
    title:<>
      <img className="edit-icon large" src="/courses/assets/icons/courses.svg" alt=""></img>
      <span className="ml-2">Courses</span>
    </>,
    classes : "border-0 btn-outline-dark",
    icon : "/courses/assets/icons/courses.svg",
    link : LMS
  },{
    title:<>
      <img className="edit-icon large" src="/courses/assets/icons/courses.svg" alt=""></img>
      <span className="ml-2">Browse Courses</span>
    </>,
    classes : "border-0 btn-outline-dark",
    icon : "/courses/assets/icons/courses.svg",
    link : BROWSE_COURSES
  }]
    
  menuItems = [];    

  

  openPage(item){    
    if(item.function){
      item.function();
    }
    if(item.link){
      window.location = item.link;
    }
    
    this.props.closeMenu();
  }

  renderMenu(){
    let menuItems = this.commonMenuItems;    
    return menuItems.map((item,index) =>{
      return (
      <li className={this.props.location.pathname === item.link ? "active" : ""} key={index}><a onClick={() => this.openPage(item)}>
        <StandardButton key={index} className={item.classes} size="btn-sm" text={item.title}/>
        </a></li>
      )
    })
  }
 
  render () {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <div className="d-xl-none">

      
      <Menu isOpen={this.props.isOpen } className="sidebar" outerContainerId={ "root" } right onStateChange={ (state) => {this.props.closeMenu(state.isOpen)} }>
        <TopNavigation inMenu={true} onMenuClick={() => this.props.closeMenu(false)}></TopNavigation>
        {this.renderMenu()}
      </Menu>
      </div>
    );
  }
}